import { useAuth } from 'modules/auth/providers/AuthProvider';
import { useRouter } from 'next/router';
import Button from 'ui/Button';

import { ErrorDiv, SubText, Text, Wrapper } from './styles';

const ErrorPage = () => {
  const router = useRouter();
  const {
    state: { user },
  } = useAuth();

  return (
    <Wrapper>
      <ErrorDiv>
        <Text>Page Not Found</Text>
        <SubText>
          {
            "Sorry, it looks like this page doesn't exist, or you don't have permission to access it."
          }
        </SubText>
        <Button
          onClick={() =>
            router.push(user?.defaultRoute ? user.defaultRoute : '/')
          }
          variant="primary"
          size="large"
        >
          {'Go To Homepage'}
        </Button>
      </ErrorDiv>
    </Wrapper>
  );
};

export default ErrorPage;
